import React,{ useState,useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useLanguage,useUser,courses } from '../../../jdemositeReact';
import OpenOrDownloadButton from './OpenOrDownloadButton';
import BuyOrAddButton from './BuyOrAddButton';



const Course = () => {
	const { _id } = useParams();
	const user = useUser();
	const language = useLanguage();
	const [ courseThumbnail,setCourseThumbnail ] = useState();

	const fetchContent = async() => {
		const courseThumbnail = await courses.getCourseThumbnailByCourseId(_id);
		setCourseThumbnail(courseThumbnail);
	}

	useEffect( () => {
		fetchContent();
	},[language]);

	const no_user = {
		en : <span><NavLink to="/login">Log in</NavLink> or <NavLink to="/register">register</NavLink> to get this course for free.</span>,
		fi : <span>Ilmainen kurssi, <NavLink to="/login">kirjaudu sisään</NavLink> tai <NavLink to="/register">rekisteröidy</NavLink> ottaaksesi sen käyttöön.</span>,
	};

	const renderButton = () => {
		return user._id ?
			courses.isMine(courseThumbnail) ?
			<OpenOrDownloadButton courseThumbnail={courseThumbnail}/>
			:
			<BuyOrAddButton courseThumbnail={courseThumbnail}/>
			:
			no_user[language]
	};

	if(!courseThumbnail) return;
	return (
		<div>
			<div>
				<img alt="" src={`/media/${courseThumbnail.contentId}.jpg`}/>
			</div>
			<div>
				{renderButton()}
			</div>
		</div>
	);
};



export default Course;
