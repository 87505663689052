import React, { useEffect,useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import Table from "../../../common/Table";
import { courses,t,useLanguage,useUser,showWarning } from '../../../jdemositeReact';
import OpenOrDownloadButton from './OpenOrDownloadButton';



const MyCourses = () => {
	const navigate = useNavigate()
	const [ headers,setHeaders ] = useState([]);
	const [ _courses,setCourses ] = useState([]);
	const user = useUser();
	const language = useLanguage();
	
	const fetchCourses = async() => {
		setHeaders([{name:"",label:"icon"},{name:t("Course"),label:"name"},{name:"",label:"open"}]);
		const { courseData,error } = await courses.getUserCourseThumbnails();
		if(error) return showWarning(error);
		const courseItems = courseData.map( (courseThumbnail) => ({
			_id : courseThumbnail._id,
			name : <NavLink to={`/courses/course/${courseThumbnail._id}`}>{courseThumbnail.name}</NavLink>,
			icon : <img src={`/media/${courseThumbnail.iconFileName}`} height="50" width="50" alt="course icon"/>,
			open : <OpenOrDownloadButton courseThumbnail={courseThumbnail}/>,
		}));
		setCourses(courseItems);
	};

	useEffect(() => {user._id ? fetchCourses() : navigate("/",{replace:true})},[user,language]);

	return (
		<div>
			<h1>{t("My courses")}</h1>
			<Table headers={headers} data={_courses} />
		</div>
	);
};



export default MyCourses;
