import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { logout } from "../../../jdemositeReact";



const Logout = () => {
	const navigate = useNavigate();

	const handleLogout = async() => {
		await logout();
		// TODO: this needs rethinking
		navigate("/",{replace:true});
	};

	useEffect(() => {handleLogout()},[]);

	return <p>Logging out</p>;
};



export default Logout;
