import React,{ useState } from 'react';
import { courses,t,showWarning } from '../../../jdemositeReact';



const OpenOrDownloadButton = ({ courseThumbnail }) => {
	const [ pdfURL,setPdfURL ] = useState(null);

	const handleOpenCourse = async() => {
		if(!pdfURL) {
			const { pdfFile,error } = await courses.getCourseContent(courseThumbnail);
			if(error) return showWarning(error);
			const _pdfURL = URL.createObjectURL(pdfFile);
			setPdfURL(_pdfURL);
			window.open(_pdfURL,"_blank").focus();
		} else {
			window.open(pdfURL,"_blank").focus();
		}
	};

	useState(() => {
		return () => {
			if(pdfURL) URL.revokeObjectURL(pdfURL);
		};
	},[]);

	return <button onClick={handleOpenCourse}>{t("Open")}/{t("Download")} PDF</button>;
};



export default OpenOrDownloadButton;
