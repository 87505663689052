import React from 'react';
import { Routes,Route,Navigate,BrowserRouter } from 'react-router-dom';
import App from "./japp/App";
import AboutWebsite from './japp/content/AboutWebsite';
import AboutCreator from './japp/content/AboutCreator';
import Resume from "./japp/content/resume/Resume";
import AboutTutor from "./japp/content/aboutTutor/AboutTutor"
import Blog from './japp/content/blog/Blog';
import BlogPost from './japp/content/blog/BlogPost';
import Editor from './japp/content/blog/Editor';
import Courses from './japp/content/courses/Courses';
import CourseCatalog from './japp/content/courses/CourseCatalog';
import MyCourses from './japp/content/courses/MyCourses';
import Course from './japp/content/courses/Course';
import Login from './japp/content/user/Login';
import Logout from './japp/content/user/Logout';
import Register from './japp/content/user/Register';
import Tscript from './japp/content/Tscript';
import NotFound from './NotFound';



const Main = () => (
	<BrowserRouter>
		<Routes>
			<Route path="/" element={<App />}>

				<Route index element={<AboutWebsite />}/>
				<Route path="author" element={<AboutCreator />}/>
				<Route path="resume" element={<Resume />}/>
				<Route path="tutor" element={<AboutTutor />}/>

				<Route path="blog" element={<Blog />}/>
				<Route path="blog/:_id" element={<BlogPost />}/>
				<Route path="blog/editor" element={<Editor />}/>
				<Route path="blog/editor/:_id" element={<Editor />}/>

				<Route path="tscript" element={<Tscript />}/>

				<Route path="login" element={<Login />}/>
				<Route path="logout" element={<Logout />}/>
				<Route path="register" element={<Register />}/>

				<Route path="courses" element={<Courses />}>
					<Route index element={<CourseCatalog />}/>
					<Route path="mycourses" element={<MyCourses />}/>
					<Route path="course/:_id" element={<Course />}/>
				</Route>

				<Route path="notfound" element={<NotFound />}/>
			</Route>
			<Route path="/resume/xx" element={<Resume />}/>
			<Route path="/*" element={<Navigate to="/notfound" />}/>
		</Routes>
	</BrowserRouter>
);



export default Main;

/*

*/
