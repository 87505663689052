/**
 * Upstream notifications
 * Application core can send notifications to be shown in the UI
 */

class NotificationService {
	#onNotificationCallbacks
	#onWarningCallbacks

	constructor() {
		this.#onNotificationCallbacks = new Set();
		this.#onWarningCallbacks = new Set();
	};

	init = () => {};

	onNotification = (callback) => this.#onNotificationCallbacks.add(callback);
	offNotification = (callback) => this.#onNotificationCallbacks.delete(callback);
	onWarning = (callback) => this.#onWarningCallbacks.add(callback);
	offWarning = (callback) => this.#onWarningCallbacks.delete(callback);

	showNotification = (message) => {
		console.log(message);
		this.#onNotificationCallbacks.forEach((callback) => callback(message));
		
	};

	showWarning = (message) => {
		console.warn(message);
		this.#onWarningCallbacks.forEach((callback) => callback(message));
	}
};



export default NotificationService;
