import Joi from "joi";
import joiObjectId from "./joi-objectid.js";



const JJoi = {
	...Joi,
	objectId : joiObjectId(Joi),
	validate : (value,schema,options) => schema.validate(value,options),
};



export default JJoi;
