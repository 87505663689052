import React from 'react';



const AboutCreator = () => (
	<React.Fragment>
		<p>More about me, enemmän minusta</p>
		<p>Coming soon</p>
	</React.Fragment>
);



export default AboutCreator;



/*
My goals
 - to become proficient in backend development
   - main focus in design and usability
   - programming languages JavaScript and Python
 - mastering AWS architecture on basic level
 - mastering project management at basic level
 - continuously improve problem solving skills
*/
