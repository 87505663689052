import React from 'react';
import { NavLink } from 'react-router-dom';
import { useLanguage } from '../../jdemositeReact';



const AboutWebsite = () => {
	const language = useLanguage();

	return aboutWebsiteLangs[language];
};

const GitHubLink = ({ children=["GitHub"] }) => <NavLink to={"https://github.com/Red-Quill/jdemosite"} target="_blank">{children}</NavLink>;

const AboutWebsiteEn = () => (
	<React.Fragment>
		<h1>About Website</h1>
		<p>
			Welcome to my developer portfolio. Frontend (this site) is created with react. It is made responsive and multilingual. My main focus as a developer, however, is in the backend so the site isn't as fancy and glossy as I would like it to be. Backend is created with NodeJS using MongoDB as database and built on top of AWS using CloudFront, VPC, EC2, EFS, Application Load Balancer, S3, Docker, Route53, CodeCommit and CodeArtifact. Source code is available for viewing in here: <GitHubLink/>.
		</p>
		<div>
			<h2>Projects</h2>
			<div>
				<h3>tScript</h3>
				<p>
					A scripting language similar to Donald Knuth's TeX language but with modern data structures including object, lists, dictionaries, and classes with multiple inheritance. File I/O capabilities are also implemented. It can be used for creating interface similar to LaTeX or ConTeXt and typesetting engine similar to TeX. I'm going to use it for creating HTML+CSS documents from LaTeX-like markup. tScript is created with Python, but it is supposed to be a standard that can be implemented in other programming languages. At the moment tScript is Python module that can be used as a part of any Python application or it can be used as a standalone programming language.
				</p>
				<p>
					In the demo page you can write your own code or try and edit some samples. You can run code on server side and interact with it via a "terminal simulator" that I created for this purpose. It uses websocket to connect to the backend.
				</p>
			</div>
			<div>
				<h3>Blog</h3>
				<p>
					A simple blog page to publish my personal thoughts. Not much content at the moment but that will change. I have a lot to say. It has an online editor for site admins. Posts can be published in multiple languages and the UI prioritizes version of viewers language if it is available, English otherwise.
				</p>
			</div>
			<div>
				<h3>Courses</h3>
				<p>
					Work in progress project for publishing course content. Currently it has one sample course that has a downloadable 300 page pdf file that I have made for Finnish high school math student. It has 250 exercises with solutions and accompanying theory sections. It is created using a modular library of LaTeX files hosted in private git repository. It cand be used to make other publications, and I have used it to publish materials for my previous employers' courses.
				</p>
			</div>
		</div>
	</React.Fragment>
);

const AboutWebsiteFi = () => (
	<React.Fragment>
		<h1>Portfolioni</h1>
		<p>
			Tervetuloa. Tässä tietoa tekemästäni sivustosta. Frontend on tehty reactilla. Tärkeimpinä prioriteetteina ovat olleet responsiivisuus ja monikielisyys. Päätavoitteeni ohjelmistokehittäjänä on kuintekin backend, joten sivun ulkoasu ei ehkä ole niin kiiltävä kuin haluaisin sen olevan. Backend-puoli on tehty Nodella, tietokantana on MondoDB ja kokonaisuus on rakennettu AWS:n päälle käyttäen teknologioita CloudFront, VPC, EC2, EFS, Application Load Balancer, S3, Docker, Route53, CodeCommit ja CodeArtifact. Lähdekoodin katseluversio on täällä: <GitHubLink/>.
		</p>
		<div>
			<h2>Projektit</h2>
			<div>
				<h3>tScript</h3>
				<p>
					Donald Knuthin TeX-kielen kaltainen skriptikieli, jossa on kuitenkin modernit tietorakenteet, kuten objektit, listat, luokat moniperinnällä. Lisäksi luonnollisesti tiedostojen luku ja kirjoitus. Sitä voi käyttää mm. rakentamaan LaTeX- ConTeXt-järjestelmiä vastaavia rajapintoja ja vaikka kokonaisen ladontajärjestelmän. Aion käyttää sitä HTML-dokumenttien luomiseen LaTeX-tyylisen markupin avulla. tScript on toteutetu Pythonilla, mutta sen on tarkoitus olla standardi, jonka voi implementoida myös muille ohjelmointikielille. tScript on Python-moduuli, jota voi käyttää osana mitä tahansa Python-ohjelmaa tai omana sovelluksenaan.
				</p>
				<p>
					Demosivulla voit kokeilla tScript-koodia ja kirjoittaa sitä joko itse tai kokeilla muutamia näytekoodeja. Koodi ajetaan palvelimella ja sen kanssa voi vuorovaikuttaa "terminaalisimulaattorin" kautta. Yhteys palvelimeen koodin suorituksen aikana hoituu websocketin avulla.
				</p>
			</div>
			<div>
				<h3>Blogi</h3>
				<p>
					Yksinkertainen blogisivu omien ajatusteni jakamiseen. Sisältöä ei vielä juurikaan ole, mutta se muuttuu; minulla on paljon sanottavaa. Siinä on online-editori admin-käyttäjille. Kirjoitukset voi julkaista useammalla kielellä ja UI näyttää käyttäjille oman kielen mukaisen version tai jos sitä ei ole saatavilla, englanninkielisen.
				</p>
			</div>
			<div>
				<h3>Kurssit</h3>
				<p>
					Työn alla oleva projekti kurssimaterialien ja muun sisällön julkaisemiseen. Tällä hetkellä siinä on yksi ladattava 300-sivuinen pdf-tiedosto, jonka olen tehnyt lukiomatematiikasta. Siinä on 250 harjoitusta ja niihin liittyvät teoriat. Olen rakentanut LaTeX järjestelmällä modulaarisen kirjaston tehtäviä ja teorioita, jotka ovat yksityisessä git-repositoriossa. Siitä pystyy rakentamaan julkaisuja ja olen sitä käyttänyt aiempien työnantajien kurssien materiaalien tuottamiseen.
				</p>
			</div>
		</div>
	</React.Fragment>
);

const aboutWebsiteLangs = {
	"en" : <AboutWebsiteEn />,
	"fi" : <AboutWebsiteFi />,
};



export default AboutWebsite;
