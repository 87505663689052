import { User,noUser } from "jblog";



class UserService {
	#httpService;
	#currentUser;
	#sessionToken;
	#onUserCallbacks;
	#onTermsAcceptedCallbacks;

	constructor() {
		this.#sessionToken = "";
		this.#currentUser = noUser;
		this.#onUserCallbacks = new Set();
		this.#onTermsAcceptedCallbacks = new Set();
	};

	init = (httpService) => {
		this.#httpService = httpService;
		this.#restoreUser();
	};

	get currentUser() {return this.#currentUser};
	get user() {return this.#currentUser};
	get sessionToken() {return this.#sessionToken};
	get termsAccepted() {return this._termsAccepted()}

	// --> Terms accepted
	#emitTermsAccepted = () => {
		const termsAccepted = this.termsAccepted;
		this.#onTermsAcceptedCallbacks.forEach((callback) => callback(termsAccepted));
	};
	_termsAccepted = () => this.#sessionToken || window.localStorage.getItem("termsAccepted") ? true : false;
	setTermsAccepted = (areThey) => {
		window.localStorage.setItem("termsAccepted",areThey);
		this.#emitTermsAccepted();
	};
	onTermsAccepted = (callback) => this.#onTermsAcceptedCallbacks.add(callback);
	offTermsAccepted = (callback) => this.#onTermsAcceptedCallbacks.delete(callback);
	// <-- Terms accepted

	// TODO for register,login, logout: throw errors instead of returning them
	register = async({ email,name,password }) => {
		await this.#httpService.post("/api/user/register",{ email,name,password },{ noteError:true });
	};
	
	login = async({ email,password }) => {
		const { headers } = await this.#httpService.post("/api/user/login",{ email,password },{ noteError:true });
		const sessionToken = headers["x-auth-token"];
		const { data } = await this.#httpService.get("/api/user/current",{ headers:{ "x-auth-token":sessionToken } },{ noteError:true });
		const user = new User(data);
		this.#setUser(sessionToken,user);
	};
	
	registerAndLogin = async({ email,name,password }) => {
		await this.register({ email,name,password });
		await this.login({ email,password });
	};
	
	logout = async() => {
		// notice that posting logout is not awaited (on purpose)
		if(this.#sessionToken)
			this.#httpService.post("/api/user/logout",true,{ headers:{ "x-auth-token":this.#sessionToken } },{ returnError:true });
		this.#resetUser();
	};

	// -->
	// Keep in-memry user data and localStorage in sync

	// used when login is called
	#setUser = (sessionToken,user) => {
		this.#sessionToken = sessionToken;
		this.#currentUser = user;
		this.#storeUserToLocalStorage();
		this.#emitUserChange();
	};

	// used when logout is called
	#resetUser = () => {
		this.#sessionToken = "";
		this.#currentUser = noUser;
		this.#resetUserLocalStorage();
		this.#emitUserChange();
	};

	// used when module is reloaded
	#restoreUser = async() => {
		if(
			!window.localStorage.getItem("userSessionToken") ||
			!window.localStorage.getItem("user")
		)
			this.#resetUser();
		else {
			// check that session is still open
			const sessionToken = window.localStorage.getItem("userSessionToken");
			const { status } = await this.#httpService.get("/api/user/current",{ headers:{ "x-auth-token":sessionToken },returnError:true });
			if(status === 401) this.#resetUser();
			else {
				this.#retrieveUserFromLocalStorage();
				this.#emitUserChange();
			}
		}
	};

	// --> whenever user changes
	onUserChanged = (callback) => this.#onUserCallbacks.add(callback);
	offUserChanged = (callback) => this.#onUserCallbacks.delete(callback);
	#emitUserChange = () => this.#onUserCallbacks.forEach((callback) => callback(this.#currentUser));
	// <--

	// Technically naming conventions of this part could cause conflicts in unlikely scenarios
	#storeUserToLocalStorage = () => {
		window.localStorage.setItem("userSessionToken",this.#sessionToken);
		window.localStorage.setItem("user",JSON.stringify(this.#currentUser._object));
	};
	
	#resetUserLocalStorage = () => {
		window.localStorage.setItem("userSessionToken","");
		window.localStorage.setItem("user","");
	};
	
	#retrieveUserFromLocalStorage = () => {
		this.#sessionToken = window.localStorage.getItem("userSessionToken");
		const _user = window.localStorage.getItem("user");
		this.#currentUser = _user ? new User(JSON.parse(_user)) : noUser;
	};
	// <--
}



export default UserService;
