import { Course } from "jblog";



class CourseService {
	#updateInterval;
	#lastUpdated;
	#coursesLastUpdated;
	#courses;
	#httpService;
	#localizationService;
	#userService;
	#userCourses;
	#ownTable;
	#content;

	constructor() {
		this.#courses = new Map();
		this.#userCourses = new Map();
		this.#ownTable = {};
		this.#content = {};
	}

	init = (httpService,localizationService,userService) => {
		this.#updateInterval = 600_000;
		this.#lastUpdated = 0;
		this.#coursesLastUpdated = 0;
		this.#httpService = httpService;
		this.#localizationService = localizationService;
		this.#userService = userService;
		this.#userService.onUserChanged( (user) => this.updateUserCourses() )
	};

	maybeUpdate = async() => {
		if(Date.now() - this.#lastUpdated > this.#updateInterval) await this.update();
	};

	update = async() => {
		const { data:{ coursesLastUpdated } } = await this.#httpService.get("/api/courses/lastupdated",{ noteError:true, });
		if(this.#coursesLastUpdated < coursesLastUpdated) {
			await this.updateCourses();
			this.#coursesLastUpdated = coursesLastUpdated;
		}
		this.#lastUpdated = Date.now();
	};

	updateCourses = async() => {
		const { data,error } = await this.#httpService.get("/api/courses/catalog");
		if(error) return { error };
		for(const course of data)
			this.#courses.set(course._id,new Course(course));
		return {};
	};

	updateUserCourses = async() => {
		await this.maybeUpdate();
		const sessionToken = this.#userService.sessionToken;
		if(!sessionToken) {
			this.clearUserCourses();
			return { error:"No user logged in" };
		};
		const { data,error } = await this.#httpService.get("/api/courses/my",{ headers:{ "x-auth-token":sessionToken } });
		if(error) return { error };
		this.clearUserCourses();
		for(const courseId of data) {
			this.#userCourses.set(courseId,this.#courses.get(courseId));
			this.#ownTable[courseId] = true;
		}
		return {};
	}

	clearUserCourses = () => {
		this.#userCourses.clear();
		this.#ownTable = {};
		this.#content = {};
	}

	getCourseCatalog = async() => {
		await this.maybeUpdate();
		const courses = this.#courses.values();
		const languages = this.#localizationService.getLanguages();
		const courseData = [];
		for(const course of courses)
			courseData.push(this.#findThumbnailByLanguages(course,languages));
		return { courseData };
	};
	
	getCourseThumbnailByCourseId = async(courseId) => {
		await this.maybeUpdate();
		const course = this.#courses.get(courseId);
		const languages = this.#localizationService.getLanguages();
		const courseThumbnail = this.#findThumbnailByLanguages(course,languages);
		return courseThumbnail;
	}

	#findThumbnailByLanguages = (course,languages) => {
		for(const language of languages) {
			const thumbnail = course.getThumbnailLang(language);
			if(thumbnail) return thumbnail;
		}
	};

	// TODO: check if users courses are cached and if not save to cache
	getUserCourseThumbnails = async() => {
		await this.maybeUpdate();
		const languages = this.#localizationService.getLanguages();
		const userCourses = this.#userCourses.values();
		const userCourseThumbnails = [];
		for(const course of userCourses)
			userCourseThumbnails.push(this.#findThumbnailByLanguages(course,languages));
		return { courseData:userCourseThumbnails };
	};
	
	getCourseContent = async(thumbnail) => {
		const sessionToken = this.#userService.sessionToken;
		if(!sessionToken) return { error:"No user logged in" };
		const { _id,language } = thumbnail;
		let pdfFile = null;
		if(this.#content[_id]) {
			pdfFile = this.#content[_id];
		} else {
			const { data,error } = await this.#httpService.get(`/api/courses/content/${_id}/${language}`,{ responseType:"blob",headers:{ "x-auth-token":sessionToken },returnError:true });
			if(error) return { error };
			pdfFile = new Blob([data], { type: "application/pdf" });
			this.#content[_id] = pdfFile;
		}
		return { pdfFile };
	};
	
	// now course = course._id
	// communicate with userService or something that caches user specific stuff
	addCourseToUser = async(course) => {
		const sessionToken = this.#userService.sessionToken;
		const { error } = await this.#httpService.put("/api/courses/my/add",{ courseId:course._id },{ headers:{ "x-auth-token":sessionToken } });
		if(error) return { error };
		await this.updateUserCourses();
		return {};
	};

	isMine = (thumbnail) => this.#userCourses.has(thumbnail._id);
};



export default CourseService;
