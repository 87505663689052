import React from 'react';



const NavBarContext = React.createContext();
NavBarContext.displayName = "NavBarContext";

const AppSizeContext = React.createContext();
AppSizeContext.displayName = "AppSizeContext";



export { NavBarContext,AppSizeContext };
