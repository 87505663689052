import BlogService from "./blogService";
import BlogEditorService from "./blogEditorService";
import CourseService from "./courseService";
import HttpService from "./httpService";
import LocalizationService from "./i18next";
import NotificationService from "./notificationService";
import UserService from "./userService";



class JdemositeCore {
	#courses;
	#blog;
	#blogEditor;
	#http;
	#localization;
	#notifications;
	#users;
	get courses() {return this.#courses}
	get blog() {return this.#blog}
	get blogEditor() {return this.#blogEditor}
	get notifications() {return this.#notifications};
	
	constructor() {
		this.#courses = new CourseService();
		this.#blog = new BlogService();
		this.#blogEditor = new BlogEditorService();
		this.#http = new HttpService();
		this.#localization = new LocalizationService();
		this.#notifications = new NotificationService();
		this.#users = new UserService();
	}

	init = () => {
		this.#localization.init();
		this.#notifications.init();
		this.#http.init(this.#notifications);
		this.#users.init(this.#http);
		this.#blog.init(this.#localization,this.#http,this.#users);
		this.#blogEditor.init(this.#blog);
		this.#courses.init(this.#http,this.#localization,this.#users);
	};

	get user() {return this.#users.user}
	get language() {return this.#localization.language}
	get languages() {return this.#localization.languages}
	get termsAccepted() {return this.#users.termsAccepted}
	
	registerAndLogin = (userData) => this.#users.registerAndLogin(userData);
	login = (userData) => this.#users.login(userData);
	logout = () => this.#users.logout();
	onUserChanged = (callback) => this.#users.onUserChanged(callback);
	offUserChanged = (callback) => this.#users.offUserChanged(callback);
	setTermsAcceptedTrue = () => this.#users.setTermsAccepted(true);
	onTermsAccepted = (callback) => this.#users.onTermsAccepted(callback);
	offTermsAccepted = (callback) => this.#users.offTermsAccepted(callback);

	setLanguage = (language) => this.#localization.setLanguage(language);
	onLanguageChanged = (callback) => this.#localization.onLanguageChanged(callback);
	offLanguageChanged = (callback) => this.#localization.offLanguageChanged(callback);
	translate = (text) => this.#localization.translate(text);

}



export default JdemositeCore;
