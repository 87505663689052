import { useState, useEffect } from 'react';
import { toast } from "react-toastify";
import JdemositeCore from '../services/jdemosite';



const jdemoCore = new JdemositeCore();
jdemoCore.init();
const { setLanguage,setTermsAcceptedTrue,login,logout,registerAndLogin,translate,courses,blog,blogEditor,notifications } = jdemoCore;
const t = translate;



const useLanguage = () => {
	const [ language,_setLanguage ] = useState(jdemoCore.language);

	useEffect(() => {
		jdemoCore.onLanguageChanged(_setLanguage);
		return () => {jdemoCore.offLanguageChanged(_setLanguage)};
	},[]);

	return language;
};



const useUser = () => {
	const [ user,setUser ] = useState(jdemoCore.user);

	useEffect(() => {
		jdemoCore.onUserChanged(setUser);
		return () => {jdemoCore.offUserChanged(setUser)};
	},[]);

	return user;
};



const useTermsAccepted = () => {
	const [ termsAccepted,setTermsAccepted ] = useState(jdemoCore.termsAccepted);

	useEffect(() => {
		jdemoCore.onTermsAccepted(setTermsAccepted);
		return () => {jdemoCore.offTermsAccepted(setTermsAccepted)}
	},[]);

	return termsAccepted;
}



notifications.onNotification(toast.info);
notifications.onWarning(toast.warning);
const showNotification = (message) => notifications.showNotification(message);
const showWarning = (message) => notifications.showWarning(message);

export { useLanguage,useUser,useTermsAccepted,setTermsAcceptedTrue,setLanguage,registerAndLogin,login,logout,t,courses,blog,blogEditor,showNotification,showWarning };
