import React, { useEffect,useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useLanguage,useUser,t,blog } from '../../../jdemositeReact';



const BlogPost = () => {
	const user = useUser();
	const language = useLanguage();
	const { _id } = useParams();
	const [ content,setContent ] = useState({ postHtmlString:"",date:0,title:"" });
	
	const fetchContent = async() => {
		try {
			const postThumbnail = await blog.getPostThumbnail(_id);
			const postHtmlString = await blog.getPostContent(postThumbnail);
			setContent({ title:postThumbnail.title,date:postThumbnail.date.toString(),postHtmlString });
		} catch(error) {
			console.log(error);
			setContent({ title:"Sorry",date:Date.now().toString(),postHtmlString:"<p>Couldn't get requested blog post</p>" })
		}
	};

	useEffect(() => {fetchContent()},[language]);

	return (
		<div className='jblog-post'>
			<BackLink />
			{user.admin && <NavLink to={`/blog/editor/${_id}`}> Edit</NavLink>}
			<h1>{content.title}</h1>
			<p>{content.date}</p>
			<div dangerouslySetInnerHTML={{__html:content.postHtmlString}} />
			<BackLink />
		</div>
	);
};

const BackLink = () => {
	return <NavLink to="/blog">&lt;-- {t("back")}</NavLink>;
};



export default BlogPost;
