import React from 'react';
import { t,courses,showNotification,showWarning } from '../../../jdemositeReact';



const BuyOrAddButton = ({ courseThumbnail:{ name,course } }) => {

	const handleCourseAdd = async() => {
		const { error } = await courses.addCourseToUser(course);
		if(error) showWarning(error);
		else showNotification(`${t("Course")} ${name} ${t("$ add course message end")}`);
	};

	//later add pricing
	return  <button onClick={handleCourseAdd}>{t("Free")}, {t("add to my courses")}</button>
};



export default BuyOrAddButton;
