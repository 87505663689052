import React, { useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { NavBarContext } from '../../../Contexts';
import { NavBarLink,NavBarCollapsible } from "../../navBar/NavBar";
import { useUser,t,useLanguage } from '../../../jdemositeReact';
import userIcon from "../../../../static/profile.svg";



const Courses = () => {
	const { setNavBarCustomItems } = useContext(NavBarContext);
	const user = useUser();
	const language = useLanguage();

	const navBarUserItems = [
		{
			_id : 210,
			Type : NavBarCollapsible,
			data: {
				itemList : [
					{ _id:220,Type:NavBarLink,data:{ to:"/courses/mycourses",text:t("My courses") } },
					{ _id:230,Type:NavBarLink,data:{ to:"/logout",text:t("Sign out") } },
				],
				symbol : { src:userIcon,alt:"User menu"},
			},
		}
	];

	const navBarNoUserItems = [
		{
			_id : 240,
			Type : NavBarCollapsible,
			data: {
				itemList : [
					{ _id:250,Type:NavBarLink,data:{ to:"/register",text:t("Sign up") } },
					{ _id:260,Type:NavBarLink,data:{ to:"/login",text:t("Sign in") } },
				],
				symbol : { src:userIcon,alt:"User menu"},
			},
		}
	];

	useEffect( () => {
		setNavBarCustomItems(user._id ? navBarUserItems : navBarNoUserItems);
		return () => setNavBarCustomItems([]);
	},[user,language])

	return <Outlet />;
};



export default Courses;
